/*
Template Name: VastriFi - Admin & Dashboard Template
Author: vastrifi
Version: 1.1.0
Website: https://vastrifi.in/
Contact: vastrifi@gmail.com
File: tailwind scss File
*/

@import "fonts/fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "plugins/gridjs";
@import "plugins/select";
@import "plugins/multi-select";



.group\/menu-link:hover,
.animate {
   animation-iteration-count: 2;
   stroke-dasharray: 10;
}



